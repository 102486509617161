import React from "react"
import { Error, Status, Message } from "../sections/404.styles"

const NotFoundPage = () => (
  <Error>
    <Status>404</Status>
    <Message>Strona nie istnieje.</Message>
  </Error>
)

export default NotFoundPage
