import styled from "styled-components"
import { device, fonts } from "../utils/styles"

export const Error = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-family: ${fonts.fontPrimary};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Status = styled.p`
  font-weight: ${fonts.fontBold};
  font-size: 4.6rem;

  @media ${device.tablet} {
    font-size: 12rem;
  }
`

export const Message = styled.h1`
  font-size: 1rem;
  font-weight: ${fonts.fontBold};

  @media ${device.tablet} {
    font-size: 2.5rem;
  }
`
